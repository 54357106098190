import { BaseController } from "./base_controller";

const lunr = require("lunr");
require("lunr-languages/lunr.stemmer.support")(lunr);
require("lunr-languages/lunr.es")(lunr);

export default class extends BaseController {
  static targets = ["q", "results", "product", "form"];
  static values = {
    filter: Object,
    dispatchEvents: {
      type: Array,
      default: ["updateStock"]
    }
  };

  get scroll() {
    const scroll = new URLSearchParams(window.location.search).get("scroll");

    return scroll?.trim();
  }

  get q() {
    const q = new URLSearchParams(window.location.search).get("q");

    return q?.trim()?.replaceAll(/[:~\*\^\+\-]/gi, "");
  }

  qTargetConnected(qTarget) {
    const q = this.q;
    if (q) qTarget.value = this.q;
  }

  async resultsTargetConnected(resultsTarget) {
    const q = this.q;

    if (q) {
      await this.fetch();
      if (!window.index) return;
    }

    document.title = `${document.title.split(":")[0]}: ${q}`;

    const results = window.index.search(q).map((r) => window.data.find((d) => d.id === r.ref)).filter((d) => {
      return Object.keys(this.filterValue).every((k) => d[k] === this.filterValue[k])
    });

    resultsTarget.innerHTML = "";

    for (const result of results) {
      const data = {
        cartImage: result.image.path,
        cartTitle: result.title,
        cartUrl: result.url,
        cartPrice: result.price,
        cartLocalizedPrice: this.localizeNumber(result.price),
        cartVariantIdValue: result.variant_id,
        sku: result.sku
      };

      resultsTarget.appendChild(this.applyTemplate(data, this.productTarget));
    }

    for (const dispatchEvent of this.dispatchEventsValue) {
      this.dispatchToWindow(dispatchEvent);
    }

    const scroll = this.scroll;

    // XXX: El delay es para poder navegar luego de cargar la página
    if (scroll) setTimeout(() => document.querySelector(`#${scroll}`)?.scrollIntoView(), 100);
  }

  async fetch() {
    // Solo permite descargar uno a la vez
    if (this.fetching) return;

    this.fetching = true;
    let response;

    // Si no existe el índice, lo descarga y procesa Lunr
    if (!window.data) {
      response = await fetch("data.json");
      window.data = await response.json();
    }

    if (!window.index) {
      response = await fetch("idx.json");
      const idx = await response.json();
      window.index = lunr.Index.load(idx);
    }

    // Permitir volver a ejecutar
    this.fetching = false;
  }

  set formDisable(disable) {
    if (!this.hasFormTarget) return;

    this.formTarget.elements.forEach((x) => (x.disabled = disable));
  }
}
